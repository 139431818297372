import styled from 'styled-components';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { H2, H3 } from '../../../../styles/textStyles';
import { PracticeSubscriptionCreationData } from '../../../../types/WebUser/InvitationDataType';
import { AdminPracticeSubscriptionBranding } from './AdminPracticeSubscriptionBranding';
import { AdminPracticeSubscriptionOnboarding } from './AdminPracticeSubscriptionOnboarding';
import { AdminPracticeSubscriptionSub } from './AdminPracticeSubscriptionSub';
import { AdminPracticeSubscriptionSubPhase } from './AdminPracticeSubscriptionSubPhase';

type Props = {
  practiceData: PracticeSubscriptionCreationData;
  changePracticeData: (value: PracticeSubscriptionCreationData) => void;
  schulungsterminDate?: string;
  customPriceActive: boolean;
};

export const validateNumberInput = (input: string) => {
  if (input === '') return '0';
  let value = input.replace(/[^\d]/g, '');
  value = value.trim();
  while (value.startsWith('0') && value.length > 1) {
    value = value.slice(1);
  }
  return value;
};

export const calculateCouponPercentage = (originalPrice: number, price: number) => {
  return Math.floor(100 - (price / originalPrice) * 100);
};

export const AdminPracticeSubscriptionCreator = ({
  practiceData,
  changePracticeData,
  schulungsterminDate,
  customPriceActive,
}: Props) => {
  return (
    <Container>
      <H2>{practiceData.name}</H2>
      <SingleTextInput
        label="Praxis Name"
        value={practiceData.name}
        onChange={(value) => changePracticeData({ ...practiceData, name: value })}
      />

      <TableWrapper>
        <H3>{'Einmalige Kosten'}</H3>

        <Table>
          <TableHeader>
            <HeaderRow>
              <HeaderCell style={{ width: '80px' }}>TYP</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>ORIGINAL PREIS</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>RABATT TYPE</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>RABATT</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>REDUZIERTER PREIS</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>ZAHLUNG BEI STARTDATUM</HeaderCell>
            </HeaderRow>
          </TableHeader>
          <TableBody>
            <AdminPracticeSubscriptionBranding practiceData={practiceData} changePracticeData={changePracticeData} />
            <AdminPracticeSubscriptionOnboarding practiceData={practiceData} changePracticeData={changePracticeData} />
          </TableBody>
        </Table>
      </TableWrapper>
      <TableWrapper>
        <H3>{'Wiederkehrende Kosten'}</H3>
        <Table>
          <TableHeader>
            <HeaderRow>
              <HeaderCell style={{ width: '80px' }}> </HeaderCell>
              <HeaderCell style={{ width: '80px' }}>ORIGINAL PREIS</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>RABATT PROZENT</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>ZYKLUS</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>ITERATIONEN</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>REDUZIERTER PREIS</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>START SOFORT</HeaderCell>
              <HeaderCell style={{ width: '80px' }}>STARTDATUM</HeaderCell>
            </HeaderRow>
          </TableHeader>
          <TableBody>
            <AdminPracticeSubscriptionSub
              practiceData={practiceData}
              changePracticeData={changePracticeData}
              schulungsterminDate={schulungsterminDate}
              customPriceActive={customPriceActive}
            />
            <AdminPracticeSubscriptionSubPhase
              practiceData={practiceData}
              changePracticeData={changePracticeData}
              customPriceActive={customPriceActive}
            />
          </TableBody>
        </Table>
      </TableWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${hexWithOpacity(colors.Primary, 0.1)};
  border-radius: 20px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  min-width: 1200px;
`;

const TableHeader = styled.thead`
  border-bottom: 2px solid ${colors.White};
`;

const HeaderRow = styled.tr`
  border-bottom: 1px solid ${colors.Light};
`;

const HeaderCell = styled.th`
  padding: 12px;
  text-align: left;
  color: ${colors.Dark};
  font-weight: normal;
`;

const TableBody = styled.tbody``;
