import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BottomSheet } from '../../core/components/BottomSheet';
import { ButtonGroup } from '../../core/components/buttons/ButtonGroup';
import { LabeledSwitchButton } from '../../core/components/buttons/LabeledSwitchButton';
import { colors } from '../../styles/colors';
import { Body, H2 } from '../../styles/textStyles';
import { useHandleChangePracticePin } from './utils/useHandleChangePracticePin';

export const PracticeSettingsPinChangeScreen = () => {
  const navigate = useNavigate();
  const practiceId = useParams().practiceId;
  const { changePin, logoutTherapists, setLogoutTherapists, loading } = useHandleChangePracticePin(practiceId);

  const handleClose = () => {
    navigate(-1);
  };

  const handleChange = async () => {
    await changePin();
    handleClose();
  };
  return (
    <BottomSheet>
      <Container>
        <HeadlineContainer>
          <H2>Praxis-Pin wechseln?</H2>
          <div>
            <StyledBody>
              Bitte beachte dass damit der bisherige Praxis-Pin ungültig wird und eine weitere Anmeldung mit dem
              bisherigen Pin nicht mehr möglich ist.
            </StyledBody>
          </div>
        </HeadlineContainer>
        <SettingsContainer>
          <LabeledSwitchButton
            isChecked={logoutTherapists}
            setIsChecked={setLogoutTherapists}
            label="Alle Therapeutinnen und Therapeuten dieser Praxis ausloggen"
          />
        </SettingsContainer>
        <StyledButtonGroup
          progressButton={{
            text: 'Praxis-Pin wechseln',
            onClick: handleChange,
            loading: loading,
          }}
          textButton={{
            text: 'Abbrechen',
            onClick: handleClose,
          }}
          center
        />
      </Container>
    </BottomSheet>
  );
};

const StyledButtonGroup = styled(ButtonGroup)``;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 560px;
  padding: 100px 20px 100px 20px;
  justify-content: center;
  align-self: center;
  justify-self: center;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
