import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticeAuthData } from '../../../types/Practices/PracticeAuthDataType';

export const usePracticeAuthDataConnect = (practiceId: string) => {
  const [practiceAuthData, setPracticeAuthData] = useState<PracticeAuthData>();

  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(
      doc(db, 'practices/' + practiceId + '/documents/practiceAuthData'),
      (practiceAuthDataSnapshot) => {
        const practiceAuthData = practiceAuthDataSnapshot.data() as PracticeAuthData;
        setPracticeAuthData(practiceAuthData);
      },
    );
    return sub;
  }, [practiceId]);

  return {
    practiceAuthDataLoading: practiceAuthData === undefined,
    pin: practiceAuthData?.pin,
    pinChangeDate: practiceAuthData?.pinChangeDate,
  };
};
