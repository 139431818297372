import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePracticeAuthDataConnect } from '../../../features/settings/utils/usePracticeAuthDataConnect';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Small } from '../../../styles/textStyles';
import { IconButton } from '../buttons/IconButton';
import { TextButton } from '../buttons/TextButton';
import { PinInputField } from './PinInputField';

type Props = {
  practiceId: string;
};

export const PinDisplay = ({ practiceId }: Props) => {
  const { pin, practiceAuthDataLoading, pinChangeDate } = usePracticeAuthDataConnect(practiceId);

  const oneDay = 24 * 60 * 60 * 1000;

  const remainingHours = Math.max(
    Math.floor((new Date(pinChangeDate ?? '').getTime() + oneDay - new Date().getTime()) / 1000 / 60 / 60),
    1,
  );
  let remainingText = `Dieser Pin ist noch ${remainingHours} Stunden gültig.`;
  if (remainingHours <= 1) {
    remainingText = `Dieser Pin ist noch eine Stunde gültig.`;
  }

  const [isPinVisible, setIsPinVisible] = useState(false);
  const navigate = useNavigate();

  const togglePinVisibility = () => setIsPinVisible(!isPinVisible);
  const pathname = window.location.pathname;

  const openPracticePinSettings = () => {
    if (pathname.includes(practiceId)) {
      navigate(`pin-settings`);
    } else navigate(`${practiceId}/pin-settings`);
  };

  const openPracticePinChange = () => {
    if (pathname.includes(practiceId)) {
      navigate(`pin-change`);
    } else navigate(`${practiceId}/pin-change`);
  };

  const pinArray = pin?.split('');
  useEffect(() => {
    document.getElementById('firstPinInput')?.focus();
  }, []);

  return (
    <Container>
      <TopRow>
        <PinContainer>
          {(pinArray ?? [1, 2, 3, 4, 5, 6]).map((value, index) => (
            <PinInputField key={index} value={value} isNotVisible={!isPinVisible} loading={practiceAuthDataLoading} />
          ))}
          <IconButton iconName="Change" onClick={openPracticePinChange} stroke={colors.Medium} size={24} />
        </PinContainer>
        <ButtonContainer>
          <TextButton
            noPadding
            onClick={togglePinVisibility}
            text={`Praxis-PIN ${isPinVisible ? 'ausblenden' : 'einblenden'}`}
          />
          <TextButton noPadding onClick={openPracticePinSettings} text="Praxis-PIN Einstellungen" />
        </ButtonContainer>
      </TopRow>
      <PinValidityText>{remainingText}</PinValidityText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    align-items: center;
  }
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    justify-content: center;
    padding-bottom: 20px;
  }
`;
const PinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const PinValidityText = styled(Small)`
  color: ${colors.Primary};
  text-align: left;
  align-self: flex-start;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    align-self: center;
  }
`;
