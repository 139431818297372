import { getAuth } from 'firebase/auth';
import { useMainStore } from '../../../store/mainStore';
import { getAdminUserRoles } from '../../../store/userSlice';

export const useUserAccessRights = () => {
  const roles = useMainStore(getAdminUserRoles);
  const accessMail = getAuth().currentUser?.email;
  console.log('roles', roles);
  return {
    accessMail,
    inviteUserAccess: roles?.inviteNewUser || roles?.allRoles,
    managePracticesAccess: roles?.managePractices || roles?.allRoles,
    salesManagerAccess: roles?.salesManager || roles?.allRoles,
    salesAccess: roles?.sales || roles?.allRoles || roles?.salesAll,
    salesAllAccess: roles?.salesAll || roles?.allRoles,
    logIntoPracticeAccess: roles?.logIntoPractice || roles?.allRoles,
  };
};
