import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { PracticePinSettings } from '../../../types/Practices/PracticePinSettingsType';

export const usePracticePinSettingsConnect = () => {
  const [showPinInTherapistApp, setShowPinInTherapistApp] = useState<boolean>();
  const [sendPinViaEmail, setSendPinViaEmail] = useState<boolean>();
  const [practiceEmails, setPracticeEmails] = useState<string[]>([]);
  const [loadingPracticePinSettings, setLoadingPracticePinSettings] = useState<boolean>(false);
  const [practiceContactEmail, setPracticeContactEmail] = useState<string>('');
  const { practiceId } = useParams();

  useEffect(() => {
    const sub = onSnapshot(
      doc(db, 'practices/' + practiceId + '/documents/practicePinSettings'),
      (practicePinSettingsSnapshot) => {
        const practicePinSettings = practicePinSettingsSnapshot.data() as PracticePinSettings;
        setPracticeEmails(practicePinSettings?.contactEmail ?? []);
        setShowPinInTherapistApp(!(practicePinSettings?.pinInTherapistAppHidden ?? false));
        setSendPinViaEmail(!(practicePinSettings?.preventPinSendViaEmail ?? false));
        setLoadingPracticePinSettings(false);
      },
    );
    return sub;
  }, [practiceId]);

  useEffect(() => {
    const sub = onSnapshot(
      doc(db, 'practices/' + practiceId + '/documents/practiceContact'),
      (practiceContactSnapshot) => {
        const practiceContact = practiceContactSnapshot.data();
        console.log(practiceContact?.email);
        setPracticeContactEmail(practiceContact?.email ?? '');
      },
    );
    return sub;
  }, [practiceId]);
  return {
    showPinInTherapistApp,
    setShowPinInTherapistApp,
    sendPinViaEmail,
    setSendPinViaEmail,
    emails: !practiceEmails || practiceEmails.length === 0 ? [practiceContactEmail] : practiceEmails,
    setPracticeEmails,
    loadingPracticePinSettings,
    practiceId,
  };
};
