import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { hexWithOpacity } from '../../utils/hexWithOpacity';

type Props = {
  value: string;
  highlighted?: boolean;
  isNotVisible?: boolean;
  loading?: boolean;
};

export const PinInputField = (props: Props) => {
  if (props.loading) return <Skeleton width={22} height={40} />;
  return <StyledInput type="text" value={props.isNotVisible ? '*' : props.value} aria-disabled={true} readOnly />;
};

const StyledInput = styled.input`
  pointer-events: none;
  width: 22px;
  height: 40px;
  border-radius: 8px;
  padding: 0;
  text-align: center;
  font: 500 18px Ubuntu;
  color: ${colors.Medium};
  background-color: ${hexWithOpacity(colors.White, 0.9)};
  box-shadow: none;
  border-color: transparent;
  outline: none;
`;
