import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticePayoutInfo } from '../../../types/Administration/PracticePayoutType';

export const usePayoutDocumentsConnect = (practiceId?: string) => {
  const [payoutDocuments, setPayoutDocuments] = useState<Array<PracticePayoutInfo>>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(
      query(collection(db, 'practicePayouts'), where('practiceId', '==', practiceId)),
      (payoutSnapshot) => {
        const payoutData = payoutSnapshot.docs.map((doc) => doc.data() as PracticePayoutInfo);
        setPayoutDocuments(payoutData);
      },
    );
    return sub;
  }, []);

  const payoutDocumentsPerYear = useMemo(() => {
    if (!payoutDocuments) return;
    const perYear = payoutDocuments.reduce(
      (acc, payout) => {
        const date = new Date(payout.date);
        const year = date.getFullYear();
        if (!acc[year]) acc[year] = [];
        acc[year].push(payout);
        return acc;
      },
      {} as { [year: number]: PracticePayoutInfo[] },
    );
    return perYear;
  }, [payoutDocuments]);

  const payoutFilterYears = Object.keys(payoutDocumentsPerYear || {})
    .sort()
    .reverse()
    .map((year) => ({
      text: year,
      value: year,
    }));
  return { loadingData: payoutDocuments === undefined, payoutDocuments, payoutDocumentsPerYear, payoutFilterYears };
};
