import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TagButton } from './TagButton';

type Props = {
  tags: { text: string; value: unknown }[];
  setSelected: (_selected: unknown[]) => void;
  type: 'multiSelect' | 'singleSelect';
  selected?: unknown[];
  atLeastOne?: boolean;
};

export const TagSelection = (props: Props) => {
  const selectedIndex =
    props.selected?.[0] !== undefined
      ? props.selected?.map((value) => props.tags?.findIndex((tag) => tag?.value === value))
      : [];

  const { t } = useTranslation();

  const handleSelect = (newValue: unknown, index: number) => {
    if (selectedIndex?.includes(index) && props.selected) {
      if (props.atLeastOne && props.selected.length === 1) {
        return;
      }
      const newSelected = props.selected?.splice(index, 1);
      props.setSelected(newSelected);
    } else {
      if (props.type === 'singleSelect') {
        props.setSelected([newValue]);
      } else {
        props.setSelected([...(props.selected ?? []), newValue]);
      }
    }
  };

  return (
    <Container>
      {props.tags.map((tag, index) => (
        <TagButton
          key={index}
          text={t(tag.text)}
          onClick={() => handleSelect(tag.value, index)}
          selected={selectedIndex?.includes(index)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
`;
