import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { EmailVerificationScreen } from './features/onboarding/screens/EmailVerificationScreen.tsx';
import { LoginScreen } from './features/onboarding/screens/LoginScreen.tsx';
import { OnboardingCheckoutScreen } from './features/onboarding/screens/OnboardingCheckoutScreen.tsx';

import { OnboardingPaymentTypeScreen } from './features/onboarding/screens/OnboardingPaymentTypeScreen.tsx';
import {
  OnboardingPracticeInvoiceAddressScreen,
  practiceInvoiceDataLoader,
} from './features/onboarding/screens/OnboardingPracticeInvoiceAddressScreen.tsx';
import { OverviewScreen } from './features/overview/screens/OverviewScreen.tsx';

import { usePracticeChainConnect } from './core/utils/connectFirestore/usePracticeChainConnect.ts';
import { useRefreshOutdatedApp } from './core/utils/connectFirestore/useRefreshOutdatedApp.ts';
import { useUserConnect } from './core/utils/connectFirestore/useUserConnect.ts';
import { AdminInAppLayout } from './features/admin/home/AdminInAppLayout.tsx';
import { AdminCreateSubscriptionScreen } from './features/admin/sales/screens/AdminCreateSubscriptionScreen.tsx';
import {
  adminPartnerInvitationLoader,
  AdminPartnerInvitationScreen,
} from './features/admin/sales/screens/AdminPartnerInvitationScreen.tsx';
import { AdminSalesBenefitsScreen } from './features/admin/sales/screens/AdminSalesBenefitsScreen.tsx';
import { AdminSalesCalculatorScreen } from './features/admin/sales/screens/AdminSalesCalculatorScreen.tsx';
import { AdminSalesEasyPlanConfiguratorScreen } from './features/admin/sales/screens/AdminSalesEasyPlanConfiguratorScreen.tsx';
import { AdminSalesManagerScreen } from './features/admin/sales/screens/AdminSalesManagerScreen.tsx';
import { AdminSalesToolsScreen } from './features/admin/sales/screens/AdminSalesToolsScreen.tsx';
import { AdminSettingsAccountScreen } from './features/admin/settings/AdminSettingsAccountScreen.tsx';
import { AdminSettingsTeamScreen } from './features/admin/settings/AdminSettingsTeamScreen.tsx';
import { useAdminUsersConnect } from './features/admin/settings/utils/useAdminUsersConnect.ts';
import { AdminPracticesOverviewScreen } from './features/admin/support/screens/AdminPracticesOverviewScreen.tsx';
import { AdminSendGroupMailModal } from './features/admin/support/screens/AdminSendGroupMailModal.tsx';
import { AdminLayout } from './features/admin/welcome/AdminLayout.tsx';
import { AdminWelcomeLayout } from './features/admin/welcome/AdminWelcomeLayout.tsx';
import { AdminChangeUserAccessModal } from './features/admin/welcome/screens/AdminChangeUserAccessModal.tsx';
import { AdminHomeScreen } from './features/admin/welcome/screens/AdminHomeScreen.tsx';
import { AdminInviteUserModal } from './features/admin/welcome/screens/AdminInviteUserModal.tsx';
import { AdminLoginScreen } from './features/admin/welcome/screens/AdminLoginScreen.tsx';
import { AdminRegistrationScreen } from './features/admin/welcome/screens/AdminRegistrationScreen.tsx';
import { AppBrandingAddressScreen } from './features/appBranding/screens/AppBrandingAddressScreen.tsx';
import { AppBrandingClaimScreen } from './features/appBranding/screens/AppBrandingClaimScreen.tsx';
import { AppBrandingColorScreen } from './features/appBranding/screens/AppBrandingColorScreen.tsx';
import { AppBrandingDescriptionScreen } from './features/appBranding/screens/AppBrandingDescriptionScreen.tsx';
import { AppBrandingImagesScreen } from './features/appBranding/screens/AppBrandingImagesScreen.tsx';
import { AppBrandingIntroScreen } from './features/appBranding/screens/AppBrandingIntroScreen.tsx';
import { AppBrandingLogoScreen } from './features/appBranding/screens/AppBrandingLogoScreen.tsx';
import { AppBrandingOpeningScreen } from './features/appBranding/screens/AppBrandingOpeningScreen.tsx';
import { AppBrandingServicesScreen } from './features/appBranding/screens/AppBrandingServicesScreen.tsx';
import { AppBrandingSummaryScreen } from './features/appBranding/screens/AppBrandingSummaryScreen.tsx';
import { AppBrandingTherapistsScreen } from './features/appBranding/screens/AppBrandingTherapistsScreen.tsx';
import { AppBrandingVideoScreen } from './features/appBranding/screens/AppBrandingVideoScreen.tsx';
import { EmailChangeScreen } from './features/onboarding/screens/EmailChangeScreen.tsx';
import { OnboardingDefaultScreen } from './features/onboarding/screens/OnboardingDefaultScreen.tsx';
import { OnboardingFeedbackScreen } from './features/onboarding/screens/OnboardingFeedbackScreen.tsx';
import {
  OnboardingPracticeContactScreen,
  practiceContactDataLoader,
} from './features/onboarding/screens/OnboardingPracticeContactScreen.tsx';
import { OnboardingPracticeDataInfoScreen } from './features/onboarding/screens/OnboardingPracticeDataInfoScreen.tsx';
import { OnboardingPracticeNamesScreen } from './features/onboarding/screens/OnboardingPracticeNamesScreen.tsx';
import {
  OnboardingPracticePayoutDataScreen,
  practicePayoutDataLoader,
} from './features/onboarding/screens/OnboardingPracticePayoutDataScreen.tsx';
import {
  OnboardingPracticeStarterPackageScreen,
  practiceStarterPackageLoader,
} from './features/onboarding/screens/OnboardingPracticeStarterPackageScreen.tsx';
import {
  OnboardingPracticeTherapistsScreen,
  practiceTherapistsDataLoader,
} from './features/onboarding/screens/OnboardingPracticeTherapistsScreen.tsx';
import { OnboardingReferralScreen } from './features/onboarding/screens/OnboardingReferralScreen.tsx';
import {
  OnboardingSMSNamesScreen,
  smsNamesDataLoader,
} from './features/onboarding/screens/OnboardingSMSNamesScreen.tsx';
import { OnboardingUserBasicInfoScreen } from './features/onboarding/screens/OnboardingUserBasicInfoScreen.tsx';
import { OnboardingVerificationScreen } from './features/onboarding/screens/OnboardingVerificationScreen.tsx';
import { PasswordResetScreen } from './features/onboarding/screens/PasswordResetScreen.tsx';
import { PaymentStatusScreen } from './features/onboarding/screens/PaymentStatusScreen.tsx';
import { RegistrationScreen } from './features/onboarding/screens/RegistrationScreen';
import { SMSPreviewModalScreen } from './features/onboarding/screens/SMSPreviewModalScreen.tsx';
import { WelcomeScreen } from './features/onboarding/screens/WelcomeScreen.tsx';
import { useLoadInvitationData } from './features/onboarding/utils/useLoadInvitationData.ts';
import { WebAppInstallationInstructionScreen } from './features/overview/screens/WebAppInstallationInstructionScreen.tsx';
import { AppMailVerificationScreen } from './features/patientAppVerification/AppMailVerificationScreen.tsx';
import { ErrorOverlay } from './features/sentry/screens/ErrorOverlay.tsx';
import { SentryRouteErrorFallback } from './features/sentry/screens/SentryRouteErrorFallback.tsx';
import { ChangeEmailModal } from './features/settings/components/ChangeEmailModal.tsx';
import { ChangePasswordModal } from './features/settings/components/ChangePasswordModal.tsx';
import { TherapistSettingsAddModal } from './features/settings/components/TherapistSettingsAddModal.tsx';
import { TherapistSettingsDisableModal } from './features/settings/components/TherapistSettingsDisableModal.tsx';
import { TherapistSettingsRenameModal } from './features/settings/components/TherapistSettingsRenameModal.tsx';
import { PersonalSettingsScreen } from './features/settings/PersonalSettingsScreen.tsx';
import {
  brandingSettingsLoader,
  PracticeSettingsBrandingScreen,
} from './features/settings/PracticeSettingsBrandingScreen.tsx';
import { PracticeSettingsInvoiceScreen } from './features/settings/PracticeSettingsInvoiceScreen.tsx';
import { PracticeSettingsLinksScreen } from './features/settings/PracticeSettingsLinksScreen.tsx';
import { PracticeSettingsNamesScreen } from './features/settings/PracticeSettingsNamesScreen.tsx';
import { PracticeSettingsPayoutScreen } from './features/settings/PracticeSettingsPayoutScreen.tsx';
import { PracticeSettingsPinChangeScreen } from './features/settings/PracticeSettingsPinChangeScreen.tsx';
import { PracticeSettingsPinScreen } from './features/settings/PracticeSettingsPinScreen.tsx';
import { PracticeSettingsPinSettingsScreen } from './features/settings/PracticeSettingsPinSettingsScreen.tsx';
import { PracticeSettingsTeamScreen } from './features/settings/PracticeSettingsTeamScreen.tsx';
import './firebaseConfig.ts';
import { AppBrandingLayout } from './navigation/AppBrandingLayout.tsx';
import { DrawerLayout } from './navigation/DrawerLayout.tsx';
import { InAppLayout } from './navigation/InAppLayout.tsx';
import { MainLayout } from './navigation/MainLayout.tsx';
import { OnboardingLayout } from './navigation/OnboardingLayout';
import { PaymentLayout } from './navigation/PaymentLayout.tsx';
import { PhoneMockLayout } from './navigation/PhoneMockLayout.tsx';
import { PracticeSettingsLayout } from './navigation/PracticeSettingsLayout.tsx';
import { useIntercomInit } from './navigation/useIntercomInit.tsx';
import { useDetectEmailChange } from './navigation/utils/useDetectEmailChange.tsx';
import { useHandleAuthState } from './navigation/utils/useHandleAuthState.tsx';
import { WelcomeLayout } from './navigation/WelcomeLayout';
import { WithoutDrawerLayout } from './navigation/WithoutDrawerLayout.tsx';
import initI18n from './translations/i18n.config';

function App() {
  initI18n();
  useHandleAuthState();
  useDetectEmailChange();
  useIntercomInit();
  useUserConnect();
  useAdminUsersConnect();
  usePracticeChainConnect();
  useLoadInvitationData();
  useRefreshOutdatedApp();

  const AdminPaths = {
    path: '',
    element: <AdminLayout />,
    children: [
      {
        path: 'adminWelcome',
        element: <AdminWelcomeLayout />,
        children: [
          { path: '', element: <AdminRegistrationScreen /> },
          { path: 'register', element: <AdminRegistrationScreen /> },
          { path: 'login', element: <AdminLoginScreen /> },
        ],
      },
      {
        path: 'sales-tools',
        children: [
          {
            path: 'calculator',
            element: <AdminSalesCalculatorScreen />,
          },
          {
            path: 'benefits',
            element: <AdminSalesBenefitsScreen />,
          },
          {
            path: 'easy-plan-configurator',
            element: <AdminSalesEasyPlanConfiguratorScreen />,
          },
        ],
      },

      {
        path: 'admin',
        element: <AdminInAppLayout />,
        children: [
          {
            path: '',
            element: <Navigate to={'./home'} />,
          },
          {
            path: 'home',
            element: <AdminHomeScreen />,
          },
          {
            path: 'sales',
            children: [
              {
                path: 'tools',
                element: <AdminSalesToolsScreen />,
              },
              {
                path: 'partner-invitations',
                element: <AdminPartnerInvitationScreen />,
                loader: adminPartnerInvitationLoader,
              },
              { path: 'create-subscription/:invitationId', element: <AdminCreateSubscriptionScreen /> },
              { path: 'subscription-settings', element: <AdminSalesManagerScreen /> },
            ],
          },
          {
            path: 'support',
            children: [
              {
                path: 'practices-overview',
                element: <AdminPracticesOverviewScreen />,
                children: [
                  {
                    path: 'send-group-mail',
                    element: <AdminSendGroupMailModal />,
                  },
                ],
              },
            ],
          },
          {
            path: 'settings',
            children: [
              {
                path: 'account',
                element: <AdminSettingsAccountScreen />,
                children: [{ path: 'change-password', element: <ChangePasswordModal /> }],
              },
              {
                path: 'team',
                element: <AdminSettingsTeamScreen />,
                children: [
                  {
                    path: 'invite',
                    element: <AdminInviteUserModal />,
                  },
                  {
                    path: 'access',
                    element: <AdminChangeUserAccessModal />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const NewPracticePaths = {
    path: ':practiceId',
    children: [
      { path: 'practice-data-info', element: <OnboardingPracticeDataInfoScreen /> },
      {
        path: 'practice-payout-data',
        element: <OnboardingPracticePayoutDataScreen />,
        loader: practicePayoutDataLoader,
      },
      {
        path: 'practice-therapists',
        element: <OnboardingPracticeTherapistsScreen />,
        loader: practiceTherapistsDataLoader,
      },
      {
        path: 'practice-contact',
        element: <OnboardingPracticeContactScreen />,
        loader: practiceContactDataLoader,
      },
      {
        path: 'practice-starter',
        element: <OnboardingPracticeStarterPackageScreen />,
        loader: practiceStarterPackageLoader,
      },
      {
        element: <PaymentLayout />,
        children: [
          { path: 'payment-type', element: <OnboardingPaymentTypeScreen /> },
          {
            path: 'invoice-address',
            element: <OnboardingPracticeInvoiceAddressScreen />,
            loader: practiceInvoiceDataLoader,
          },
          {
            path: 'checkout',
            element: <OnboardingCheckoutScreen />,
            loader: practiceInvoiceDataLoader,
          },
          // { path: 'contract', element: <OnboardingContractScreen />, loader: practiceContractLoader },
        ],
      },
      { path: 'payment-status', element: <PaymentStatusScreen /> },
    ],
  };

  const AppBrandingPath = {
    path: 'branding/:practiceId',
    element: <AppBrandingLayout />,
    children: [
      { path: 'intro', element: <AppBrandingIntroScreen /> },
      {
        path: '',
        element: <PhoneMockLayout />,
        children: [
          { path: 'color', element: <AppBrandingColorScreen /> },
          { path: 'logo', element: <AppBrandingLogoScreen /> },
          { path: 'address', element: <AppBrandingAddressScreen /> },
          { path: 'opening', element: <AppBrandingOpeningScreen /> },
          {
            path: 'therapists',
            element: <AppBrandingTherapistsScreen />,
          },
          { path: 'claim', element: <AppBrandingClaimScreen /> },
          { path: 'services', element: <AppBrandingServicesScreen /> },
          { path: 'images', element: <AppBrandingImagesScreen /> },
          { path: 'description', element: <AppBrandingDescriptionScreen /> },
          { path: 'intro-video', element: <AppBrandingVideoScreen /> },
          { path: 'summary', element: <AppBrandingSummaryScreen /> },
        ],
      },
    ],
  };

  const OnboardingPaths = {
    path: 'onboarding',
    element: <OnboardingLayout />,
    children: [
      {
        index: true,
        element: <OnboardingDefaultScreen />,
      },
      { path: 'verification', element: <OnboardingVerificationScreen /> },
      { path: 'basic-info', element: <OnboardingUserBasicInfoScreen /> },
      { path: 'referral', element: <OnboardingReferralScreen /> },
      { path: 'practices-names', element: <OnboardingPracticeNamesScreen /> },
      NewPracticePaths,
      { path: 'feedback', element: <OnboardingFeedbackScreen /> },
      {
        path: 'sms-names',
        element: <OnboardingSMSNamesScreen />,
        loader: smsNamesDataLoader,
        children: [
          {
            path: 'sms-preview',
            element: <SMSPreviewModalScreen />,
          },
        ],
      },
    ],
  };

  const WelcomePaths = {
    path: 'welcome',
    element: <WelcomeLayout />,
    children: [
      { path: '', element: <WelcomeScreen /> },
      { path: 'registration', element: <RegistrationScreen /> },
      { path: 'login', element: <LoginScreen /> },
      { path: 'password-reset', element: <PasswordResetScreen /> },
    ],
  };

  const PracticeSettingsPath = {
    path: 'practice-settings/:practiceId',
    element: <PracticeSettingsLayout />,
    children: [
      {
        path: 'pin',
        element: <PracticeSettingsPinScreen />,
        children: [
          { path: 'pin-settings', element: <PracticeSettingsPinSettingsScreen /> },
          { path: 'pin-change', element: <PracticeSettingsPinChangeScreen /> },
        ],
      },

      { path: 'links', element: <PracticeSettingsLinksScreen /> },
      { path: 'name', element: <PracticeSettingsNamesScreen /> },
      {
        path: 'team',
        element: <PracticeSettingsTeamScreen />,
        children: [
          { path: 'add-therapist', element: <TherapistSettingsAddModal /> },
          { path: 'rename-therapist', element: <TherapistSettingsRenameModal /> },
          { path: 'disable-therapist', element: <TherapistSettingsDisableModal /> },
        ],
      },
      { path: 'payout', element: <PracticeSettingsPayoutScreen /> },
      { path: 'invoice', element: <PracticeSettingsInvoiceScreen /> },
      { path: 'branding-settings', element: <PracticeSettingsBrandingScreen />, loader: brandingSettingsLoader },
      { path: '', element: <Navigate to="links" /> },
    ],
  };

  const MainPaths = {
    path: '/',
    children: [
      {
        element: <WithoutDrawerLayout />,
        children: [
          { element: <WebAppInstallationInstructionScreen />, path: 'webapp-installation-instruction' },
          {
            path: 'new-practice',
            children: [{ path: 'practices-names', element: <OnboardingPracticeNamesScreen /> }, NewPracticePaths],
          },
        ],
      },
      {
        element: <DrawerLayout />,
        children: [
          { path: '', element: <Navigate to="/overview" replace={true} /> },
          {
            path: 'overview',
            element: <OverviewScreen />,
            loader: practiceStarterPackageLoader,
            children: [
              { path: ':practiceId/pin-settings', element: <PracticeSettingsPinSettingsScreen /> },
              { path: ':practiceId/pin-change', element: <PracticeSettingsPinChangeScreen /> },
            ],
          },
          {
            path: 'personal-data',
            element: <PersonalSettingsScreen />,
            children: [
              { path: 'change-email', element: <ChangeEmailModal /> },
              { path: 'change-password', element: <ChangePasswordModal /> },
            ],
          },
          PracticeSettingsPath,
        ],
      },
    ],
  };

  const InAppPaths = {
    element: <InAppLayout />,
    children: [MainPaths, OnboardingPaths, AppBrandingPath],
  };

  const router = createBrowserRouter([
    {
      errorElement: <SentryRouteErrorFallback />,
      element: <MainLayout />,
      children: [
        { path: 'verification', element: <EmailVerificationScreen /> },
        { path: 'app-verification', element: <AppMailVerificationScreen /> },
        { path: 'change-verification', element: <EmailChangeScreen /> },
        WelcomePaths,
        InAppPaths,
        AdminPaths,
        { path: '*', element: <ErrorOverlay type={'404'} /> },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<ErrorOverlay type={'404'} />} />;
}

export default App;
