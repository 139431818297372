import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BottomSheet } from '../../core/components/BottomSheet';
import { LabeledSwitchButton } from '../../core/components/buttons/LabeledSwitchButton';
import { MainButton } from '../../core/components/buttons/MainButton';
import { SingleTextInput } from '../../core/components/textInput/SingleTextInput';
import { editPracticePinSettings } from '../../core/utils/editFirestore/editPracticePinSettings';
import { useMainStore } from '../../store/mainStore';
import { colors } from '../../styles/colors';
import { Body, H2, H4 } from '../../styles/textStyles';
import { usePracticePinSettingsConnect } from './utils/usePracticePinSettingsConnect';

export const PracticeSettingsPinSettingsScreen = () => {
  const navigate = useNavigate();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [loading, setLoading] = useState(false);
  const {
    showPinInTherapistApp,
    setShowPinInTherapistApp,
    sendPinViaEmail,
    setSendPinViaEmail,
    emails,
    setPracticeEmails,
    practiceId,
  } = usePracticePinSettingsConnect();

  const toggleShowPinInTherapistApp = () => setShowPinInTherapistApp(!showPinInTherapistApp);
  const toggleSendPinViaEmail = () => setSendPinViaEmail(!sendPinViaEmail);

  const updatePracticePinSettings = async () => {
    if (practiceId)
      try {
        setLoading(true);
        // update practice pin settings in firestore
        await editPracticePinSettings(practiceId, {
          pinInTherapistAppHidden: !showPinInTherapistApp,
          preventPinSendViaEmail: !sendPinViaEmail,
          contactEmail: emails,
        });
        setEventIndicator('success', 'Praxis-Pin Einstellungen erfolgreich gespeichert');
        navigate(-1);
      } catch (error) {
        setEventIndicator('error', 'Fehler beim Speichern der Praxis-Pin Einstellungen');
        console.error('Error writing document: ', error);
      } finally {
        setLoading(false);
      }
  };

  const handleEmailChange = (email: string, index: number) => {
    const newEmails = [...emails];
    newEmails[index] = email;
    setPracticeEmails(newEmails);
  };

  return (
    <BottomSheet>
      <Container>
        <HeadlineContainer>
          <H2>Praxis-Pin Einstellungen</H2>
          <div>
            <StyledBody>
              Zur Anmeldung in die Therapeuten-App wird ein Praxis-Pin benötigt. Dieser Praxis-Pin wird aus
              Sicherheitsgründen alle 24 Stunden erneuert.
              <br />
              <br />
              Bereits angemeldete Nutzer der Therapeuten-App müssen den Praxis-Pin nicht erneut eingeben, sondern
              bleiben automatisch in der App angemeldet.
              <br />
              <br />
              Abgemeldete Nutzer können, um sich anzumelden, den aktuellen Praxis-Pin bei bereits angemeldeten
              Team-Mitgliedern anfordern.
            </StyledBody>
          </div>
        </HeadlineContainer>
        <SettingsContainer>
          <H4>Hierfür gibt es folgende Optionen:</H4>
          <LabeledSwitchButton
            isChecked={showPinInTherapistApp ?? false}
            setIsChecked={toggleShowPinInTherapistApp}
            label="Anzeige des Praxis-Pins in der Therapeuten-App"
          />
          <LabeledSwitchButton
            isChecked={sendPinViaEmail ?? false}
            setIsChecked={toggleSendPinViaEmail}
            label="Praxis-Pin via Email senden"
          />
          <div>
            {emails?.map((email, index) => (
              <SingleTextInput
                placeholder="praxis-email@praxis.de"
                value={email}
                onChange={(value) => handleEmailChange(value, index)}
                label={index === 0 ? 'Email-Adresse für Praxis-Pin Benachrichtigung' : undefined}
                disabled={!sendPinViaEmail}
              />
            ))}
          </div>
          <StyledMainButton text="Speichern" onClick={updatePracticePinSettings} loading={loading} />
        </SettingsContainer>
      </Container>
    </BottomSheet>
  );
};

const StyledMainButton = styled(MainButton)``;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 560px;
  padding: 100px 20px 100px 20px;
  justify-content: center;
  align-self: center;
  justify-self: center;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
