import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { ProgressButton } from './ProgressButton';
import { TextButton } from './TextButton';

type ButtonProps = {
  disabled?: boolean;
  text: string;
  onClick: () => void;
  loading?: boolean;
};

type Props = {
  textButton?: ButtonProps;
  progressButton?: ButtonProps & { numberOfSteps?: number; currentStep?: number };
  className?: string;
  center?: boolean;
};

export const ButtonGroup = ({ textButton, progressButton, className, center }: Props) => {
  return (
    <Container className={className} $center={center}>
      {textButton && <TextButton {...textButton} />}
      {progressButton && <ProgressButton {...progressButton} />}
    </Container>
  );
};

const Container = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
  padding: 10px 0 10px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column-reverse;
    gap: 10px;
    justify-content: flex-end;
    align-items: ${({ $center }) => ($center ? 'center' : 'flex-end')};
  }
`;
