import saveAs from 'file-saver';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import JSZip from 'jszip';
import { useState } from 'react';
import styled from 'styled-components';
import { ActionMenu } from '../../../../core/components/ActionMenu';
import { RadioButton } from '../../../../core/components/buttons/RadioButton';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { db } from '../../../../firebaseConfig';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4 } from '../../../../styles/textStyles';
import { Practice } from '../../../../types/Practices/PracticeType';
import { usePracticeAuthDataConnect } from '../../../settings/utils/usePracticeAuthDataConnect';
import { useAdminChainConnect } from '../../connectUtils/useAdminChainConnect';
import { usePracticePackageConnect } from '../../connectUtils/useAdminPracticePackageConnect';
import { useUserAccessRights } from '../../utils/useUserAccessRights';

export const AdminPracticeStatusLine = ({
  practice,
  index,
  selectPractice,
  selected,
}: {
  practice: Practice;
  index: number;
  selectPractice: () => void;
  selected: boolean;
}) => {
  const { logIntoPracticeAccess } = useUserAccessRights();
  const [loading, setLoading] = useState(false);
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const practicePackageInfo = usePracticePackageConnect(practice.id);
  const chain = useAdminChainConnect(practice.chainId);
  const practiceAuthData = usePracticeAuthDataConnect(practice.id);
  const starterPackage = practicePackageInfo?.starterPackage;
  const practiceDomain =
    import.meta.env.VITE_ENV === 'production'
      ? 'https://app.physiofit.app'
      : 'https://io-physiofit-development.web.app';
  const practiceUrl = `${practiceDomain}/newPatient?pId=${practice.id}`;

  const openLink = () => {
    copyPin();
    window.open(practiceUrl, '_blank');
  };
  const copyLink = () => {
    navigator.clipboard.writeText(practiceUrl);
    setEventIndicator('success', 'Link kopiert');
  };
  const copyLinkDashboardInvitation = () => {
    // https://www.portal.physiofit.app/welcome?id=036e0662-251e-479a-87a1-0d74b72ea2c9
    const chainDomain =
      import.meta.env.VITE_ENV === 'production'
        ? 'https://www.portal.physiofit.app/welcome?id='
        : 'https://partner-physiofit-dev.web.app/welcome?id=';
    const invitation = chainDomain + chain?.onboarding?.invitationId;
    navigator.clipboard.writeText(invitation);
    setEventIndicator('success', 'Link kopiert');
  };
  const trackPackage = () => {
    window.open(
      'https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=' + starterPackage?.shipmentId,
      '_blank',
    );
  };
  const downloadDHLLabel = () => {
    window.open(starterPackage?.labelUrl, '_blank');
  };
  const downloadQRCode = () => {
    window.open(practice.practiceLinkInfo?.qrCodeURL, '_blank');
  };
  const createDHLLabel = async () => {
    const functions = getFunctions();
    const call = httpsCallable(functions, 'createDHLLabelQRCodeManual');
    console.log(practice.id);
    try {
      const result = (await call({ practiceIds: [practice.id] })) as any;
      console.log(result);
      setEventIndicator('success', 'DHL Label erstellt');
    } catch (error: any) {
      setEventIndicator('error', 'Fehler ' + error.message);
    }
  };
  const loginAsPractice = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'createImpersonateUserToken');
      const result = (await call({ userId: chain?.ownerId })) as any;
      const token = result?.data?.token;
      signInWithCustomToken(getAuth(), token);
    } catch (error: any) {
      setEventIndicator('error', 'Fehler ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const openFirestorePractice = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2Fpractices~2F${practice.id}`,
      '_blank',
    );
  };

  const openFirestoreOwner = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2FpracticeUsers~2F${chain?.ownerId}`,
      '_blank',
    );
  };

  const openFirestoreChain = () => {
    const env = import.meta.env.VITE_ENV;
    window.open(
      `https://console.firebase.google.com/u/0/project/io-physiofit-${env}/firestore/databases/-default-/data/~2FpracticeChains~2F${practice.chainId}`,
      '_blank',
    );
  };

  const openHubspotCompany = () => {
    window.open(`https://app-eu1.hubspot.com/contacts/139853398/record/0-2/${practice.hubspot?.practiceId}`, '_blank');
  };

  const openHubspotContact = () => {
    window.open(`https://app-eu1.hubspot.com/contacts/139853398/record/0-1/${practice.hubspot?.contactId}`, '_blank');
  };

  const openHubspotChain = () => {
    window.open(`https://app-eu1.hubspot.com/contacts/139853398/record/0-2/${practice.hubspot?.chainId}`, '_blank');
  };

  const copyPin = () => {
    if (!practiceAuthData?.pin) {
      setEventIndicator('error', 'Pin nicht vorhanden');
      return;
    }
    navigator.clipboard.writeText(practiceAuthData?.pin);
    setEventIndicator('success', 'Pin kopiert');
  };
  const downloadPackageDocs = async () => {
    try {
      const qrCodeUrl = practice?.practiceLinkInfo?.qrCodeURL;
      const labelUrl = starterPackage?.labelUrl;
      if (!qrCodeUrl || !labelUrl) return;
      const zip = new JSZip();
      const fileName = `${practice.name.replace(/[^a-zA-Z0-9]/g, '_')}_label.pdf`;
      const fileNameQR = `${practice.name.replace(/[^a-zA-Z0-9]/g, '_')}_qrCode.png`;
      const responseQR = await fetch(qrCodeUrl);
      const blobQR = await responseQR.blob();
      const responseLabel = await fetch(labelUrl);
      const blobLabel = await responseLabel.blob();
      zip?.file(fileName, blobLabel);
      zip?.file(fileNameQR, blobQR);
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, practice.name + '_Paket.zip');
      setEventIndicator('success', 'Downloaded Labels');
    } catch (error: any) {
      setEventIndicator('error', 'Fehler: ' + error.message);
      console.error('Error downloading labels:', error);
      throw new Error('Failed to download labels');
    }
  };

  const toggleBetaTesting = async () => {
    await updateDoc(doc(db, `practices/${practice.id}`), { isBetaTester: !practice.isBetaTester });
    await updateDoc(doc(db, `practiceChains/${practice.chainId}`), { isBetaTester: !practice.isBetaTester });
    setEventIndicator('success', 'Beta Testing ' + (practice.isBetaTester ? 'ended' : 'started'));
  };

  let actions = [
    { label: 'Therapeuten-Link kopieren', onClick: copyLink },
    { label: 'Therapeuten-App öffnen', onClick: openLink },
    { label: 'Pin-Kopieren', onClick: copyPin },
    {
      label: 'Inhaber-Portal Einladungslink kopieren',
      onClick: copyLinkDashboardInvitation,
    },
    { label: 'Paket verfolgen', onClick: trackPackage, disabled: !starterPackage?.shipmentId },
    {
      label: 'Paket Dokumente Downloaden',
      onClick: downloadPackageDocs,
      disabled: !starterPackage?.labelUrl || !practice.practiceLinkInfo?.qrCodeURL,
    },
    {
      label: 'DHL Label downloaden',
      onClick: downloadDHLLabel,
      disabled: !starterPackage?.labelUrl,
    },
    {
      label: 'DHL Label manuell erstellen',
      onClick: createDHLLabel,
      disabled: !!starterPackage?.labelUrl,
    },
    { label: 'QR Code downloaden', onClick: downloadQRCode, disabled: !practice.practiceLinkInfo?.qrCodeURL },
    ...(logIntoPracticeAccess
      ? [{ label: 'Als Praxis einloggen', onClick: loginAsPractice, disabled: !chain?.ownerId }]
      : []),
    { label: 'Praxis Firestore', onClick: openFirestorePractice },
    { label: 'Owner Firestore', onClick: openFirestoreOwner },
    { label: 'Chain Firestore', onClick: openFirestoreChain },
    { label: 'Practice Hubspot', onClick: openHubspotCompany, disabled: !practice.hubspot?.practiceId },
    { label: 'Contact Hubspot', onClick: openHubspotContact, disabled: !practice.hubspot?.contactId },
    { label: 'Chain Hubspot', onClick: openHubspotChain, disabled: !practice.hubspot?.chainId },
    { label: practice?.isBetaTester ? 'End Beta Testing' : 'Start Beta Testing', onClick: toggleBetaTesting },
  ];
  const migrated = chain?.onboarding?.onboardingFlags?.onboardingCompleted;

  const status = !starterPackage?.shipmentId
    ? '✍️ Registrierung'
    : starterPackage?.arrivedInfoMailDate
      ? '✅ Abgeschlossen'
      : starterPackage?.sendInfoMailDate
        ? '📦 Paket verschickt'
        : '📤 Paket bereit';

  return (
    <Container>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Index>
            <RadioButton checked={selected} onPress={selectPractice} />
          </Index>
          <Index>{index}</Index>
          <Date>{practice.createdAt?.split('T')?.[0]}</Date>
          <Name>{practice.name}</Name>
          <Name>{chain?.name}</Name>
          {practice.isMigrated ? (
            <Date>{migrated ? '✅ Migriert' : '❌ Ausstehend'}</Date>
          ) : (
            <>
              <Date>{status}</Date>
            </>
          )}

          <ActionMenu actions={actions} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${colors.Light};
  align-items: center;
  min-height: 50px;
`;

const Date = styled(H4)`
  flex: 1;
  text-align: left;
`;

const Name = styled(H4)`
  flex: 2;
  text-align: left;
`;

const Index = styled(H4)`
  width: 50px;
  text-align: left;
`;
