import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { addValueIfExists } from '../core/utils/addValueIfExists';
import { getChainId, getChainName } from '../store/chainSlice';
import { getInvitationFirstName } from '../store/invitationSlice';
import { useMainStore } from '../store/mainStore';
import { getIsMitarbeiter, getUserFirstName, getUserId, getUserMail } from '../store/userSlice';

export const useIntercomInit = () => {
  const firstName = useMainStore(getUserFirstName);
  const invitationFirst = useMainStore(getInvitationFirstName);
  const chainName = useMainStore(getChainName);
  const chainId = useMainStore(getChainId);
  const userId = useMainStore(getUserId);
  const userMail = useMainStore(getUserMail);
  const isMitarbeiter = useMainStore(getIsMitarbeiter);
  const { boot, update, shutdown } = useIntercom();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Make sure user is logged in before calling intercom hash cloud function
  useEffect(() => {
    const auth = getAuth();
    const sub = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => sub();
  }, []);

  const getIntercomUserHash = async (intercomUserId: string) => {
    const functions = getFunctions();
    const callResult = (
      await httpsCallable(
        functions,
        'webIntercomAuth',
      )({
        intercomUserId,
      })
    )?.data as any;
    return callResult.userHash;
  };

  useEffect(() => {
    if (!userId) {
      shutdown();
    }
    boot();
  }, [boot, userId]);

  useEffect(() => {
    const asyncFunc = async () => {
      let intercomUserHash = undefined;
      if (isMitarbeiter) return;
      if (userId && isLoggedIn) {
        intercomUserHash = await getIntercomUserHash(userId);
      }
      update({
        name: firstName || invitationFirst || undefined,
        languageOverride: 'German',
        userId: intercomUserHash ? userId : undefined,
        email: userMail,
        ...addValueIfExists('userHash', intercomUserHash),
        company: {
          companyId: chainId ?? 'unknown',
          name: chainName ?? 'unknown',
          customAttributes: {
            'Ist Kette': true,
          },
        },
        customAttributes: {
          webAppVersion: import.meta.env.VITE_VERSION,
          chainName: chainName ?? 'unknown',
          chainId: chainId ?? 'unknown',
          'Ist Inhaber': true,
          userType: intercomUserHash ? 'owner' : 'ownerAnonymous',
        },
      });
    };
    asyncFunc();
  }, [update, firstName, invitationFirst, chainName, chainId, userId, isLoggedIn, userMail, isMitarbeiter]);
};
