import { useIntercom } from 'react-use-intercom';

export const useIntercomOpenArticle = () => {
  const { showArticle } = useIntercom();
  const isProduction = import.meta.env.VITE_ENV === 'production';

  const toggleIntercom = (articleId: number) => {
    showArticle(isProduction ? articleId : 10489940);
  };

  return { toggleIntercom };
};
