import { forwardRef } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { useTherapistsConnect } from '../../../../core/utils/connectFirestore/useTherapistsConnect';
import { readCloudflareUrl } from '../../../../core/utils/getResolutionUrl';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4, Mini } from '../../../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../../../onboarding/utils/useOnboardingPracticeInfo';

export const MockTherapistsOverviewCard = forwardRef<HTMLDivElement>((_, ref) => {
  const { practiceId } = useOnboardingPracticeInfo();
  const { therapists } = useTherapistsConnect(practiceId);
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <Card ref={ref}>
      <Headline $color={appColors.Medium}>{'Unser Team'}</Headline>
      <DaysContainer>
        {therapists?.map((therapist, index) => {
          // TODO Do not remove profilePictureUrl. I will fix it later.
          // @ts-expect-error profilePictureUrl is not in the therapist type
          const imageUrl = therapist.profilePicture?.assetUrl ?? therapist.profilePictureUrl;
          return (
            <Day key={index}>
              {imageUrl ? (
                <Avatar src={readCloudflareUrl(imageUrl)} alt={'avatar_' + therapist.id} />
              ) : (
                <GenericSvg name="Avatar" size={30} />
              )}
              <DayText $color={appColors.Medium}>{therapist.firstName + ' ' + therapist.lastName}</DayText>
            </Day>
          );
        })}
      </DaysContainer>
    </Card>
  );
});

const Day = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const DayText = styled(Mini)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => $color};
`;

const Headline = styled(H4)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  margin-bottom: 10px;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  overflow: hidden;
  gap: 10px;
  padding: 20px 15px;
`;

const DaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;
