import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccordionComponent } from '../../../../core/components/AccordionComponent';
import { ButtonGroup } from '../../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../../core/components/container/ModalContainer';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { usePracticeConnect } from '../../../../core/utils/connectFirestore/usePracticeConnect';
import { usePracticeContactConnect } from '../../../../core/utils/connectFirestore/usePracticeContactConnect';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H3, Small } from '../../../../styles/textStyles';
import { checkIfValidEmail } from '../../../onboarding/utils/checkIfValidMail';

export const AdminSendGroupMailModal = () => {
  const navigate = useNavigate();
  const practiceIds = useLocation().state?.practices as string[];
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { practiceContact, loadingPracticeContact } = usePracticeContactConnect(practiceIds[0]);
  const { loadingPractice, practice } = usePracticeConnect(practiceIds[0]);

  const [sendGridId, setSendGridId] = useState('');
  const [testMails, setTestMails] = useState('');
  const [subject, setSubject] = useState('');
  const [preHeader, setPreHeader] = useState('');

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    navigate('..');
  };
  const testMailArray = testMails.replace(' ', '').split(',');
  const errorMail = testMailArray.find((mail) => !checkIfValidEmail(mail));

  const sendMail = async (isTest: boolean) => {
    setLoading(true);
    const functions = getFunctions();
    const sendMailFunction = httpsCallable(functions, 'sendAdminGroupMail');
    try {
      const result = (await sendMailFunction({
        sendGridId,
        subject: subject === '' ? undefined : subject,
        preHeader: preHeader === '' ? undefined : preHeader,
        testMails: isTest ? testMailArray : undefined,
        practiceIds: practiceIds,
      })) as any;
      console.log('result', JSON.stringify(result));
      if (result?.data?.status === 'error') {
        throw new Error(result?.data?.message);
      }
      setEventIndicator('success', 'Mail wurde versendet');
      handleClose();
    } catch (error: any) {
      setEventIndicator('error', 'Fehler beim Versenden der Mails ' + error.message);
      console.error('Error writing document: ', error);
    } finally {
      setLoading(false);
    }
  };

  let steps = 0;
  if (sendGridId) {
    steps++;
  }
  if (subject) {
    steps++;
  }
  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{'Gruppen Mail Versenden'}</StyledH3>
        <FormContainer>
          <SingleTextInput
            value={sendGridId}
            onChange={setSendGridId}
            placeholder={'d-1923ddd2965c480814b6f2d6340bask1234'}
            label="Sendgrid ID"
          />
          <SingleTextInput value={subject} onChange={setSubject} placeholder={'Subject'} label="Subject" />
          <SingleTextInput
            value={preHeader}
            onChange={setPreHeader}
            placeholder={'Pre-Header (optional)'}
            label="Pre-Header"
          />
          <SingleTextInput
            value={testMails}
            onChange={setTestMails}
            placeholder={'fabian@physiofit.app, tester@physiofit.app'}
            label="Test Mails (optional)"
            error={errorMail ? 'Bitte gib gültige E-Mail Adressen ein' : ''}
          />
          {testMails.length > 0 && (
            <>
              <Small>{`Erkannte Mails:`}</Small>
              {testMailArray.map((mail) => (
                <Small key={mail}>{mail}</Small>
              ))}
            </>
          )}
          {loadingPracticeContact || loadingPractice ? (
            <LoadingSpinner color={colors.Primary} size={48} />
          ) : (
            <AccordionComponent title={'Mögliche Variablen (Beispiel-Praxis: ' + practice?.name + ')'}>
              <PreviewContainer>
                <StyledSmall>{`contact_firstName: ${practiceContact?.firstName}`}</StyledSmall>
                <StyledSmall>{`contact_lastName: ${practiceContact?.lastName}`}</StyledSmall>
                <StyledSmall>{`contact_email: ${practiceContact?.email}`}</StyledSmall>
                <StyledSmall>{`contact_phoneNumber: ${practiceContact?.phoneNumber}`}</StyledSmall>
                <StyledSmall>{`practice_name: ${practice?.displayName}`}</StyledSmall>
                <StyledSmall>{`therapist_app_url: ${practice?.practiceLinkInfo?.shortLink}`}</StyledSmall>
              </PreviewContainer>
            </AccordionComponent>
          )}

          <ButtonGroup
            textButton={{
              text: 'Test Mail Versenden',
              onClick: () => sendMail(true),
              loading,
              disabled: sendGridId === '' || errorMail !== undefined || testMails === '' || subject === '',
            }}
            progressButton={{
              currentStep: steps,
              numberOfSteps: 2,
              text: 'Mail Versenden',
              onClick: () => sendMail(false),
              loading,
              disabled: sendGridId === '' || testMails !== '' || subject === '',
            }}
          />
        </FormContainer>
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Dark};
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
