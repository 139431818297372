import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { Therapist } from '../../../types/Practices/TherapistType';

export const useHandleTherapistInfo = (therapist: Therapist) => {
  const practiceId = useParams().practiceId;
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const navigate = useNavigate();
  const [copyLinkLoading, setCopyLinkLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const changeName = () => {
    navigate('rename-therapist', { state: { therapist } });
  };
  const disableTherapist = () => {
    navigate('disable-therapist', { state: { therapist } });
  };

  const practiceDomain =
    import.meta.env.VITE_ENV === 'production'
      ? 'https://app.physiofit.app'
      : 'https://io-physiofit-development.web.app';
  const therapistUrl = `${practiceDomain}/newPatient?pId=${practiceId}&tId=${therapist.id}`;
  const copyLink = () => {
    try {
      if (!practiceId) {
        throw new Error('No practice id found');
      }

      setCopyLinkLoading(true);
      setTimeout(() => {
        setCopyLinkLoading(false);
      }, 1000);
      navigator.clipboard.writeText(therapistUrl);
      setEventIndicator('success', 'Therapeuten Link kopiert');
    } catch (error) {
      console.error('Error copying link: ', error);
      setEventIndicator('error', 'Fehler beim Kopieren des Links');
    }
  };

  const openLink = () => {
    window.open(therapistUrl, '_blank');
  };

  const activeItems = [
    {
      title: 'Name Ändern',
      onClick: changeName,
    },
    {
      title: 'Persönlichen Physiofit Link kopieren',
      onClick: copyLink,
      loading: copyLinkLoading,
    },
    {
      title: 'Persönlichen Physiofit Link öffnen',
      onClick: openLink,
    },
    {
      title: 'Therapeut deaktivieren',
      onClick: disableTherapist,
      warning: true,
    },
  ];

  const inactiveItems = [
    {
      title: 'Therapeut aktivieren',
      onClick: disableTherapist,
    },
  ];

  const items: { title: string; onClick: () => void; warning?: boolean }[] = therapist.inactive
    ? inactiveItems
    : activeItems;
  const title = `${therapist.firstName} ${therapist.lastName}`;

  // TODO Do not remove profilePictureUrl. I will fix it later.
  // @ts-expect-error profilePictureUrl is not in the therapist type
  const avatarUrl = therapist.profilePicture?.assetUrl ?? therapist.profilePictureUrl;

  const MAX_FILE_SIZE_MB = 5;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFiles = async (files: FileList) => {
    const file = files[0];
    const fileSizeMB = Math.round((file.size / 1024 / 1024) * 1000) / 1000;
    if (file.size > MAX_FILE_SIZE) {
      alert(
        `Die Datei ist zu groß (${fileSizeMB}MB). Bitte wähle eine Datei mit einer Größe von maximal ${MAX_FILE_SIZE_MB}MB.`,
      );
      return;
    }
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      setLoading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `therapistImages/${therapist?.id}/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        await updateDoc(doc(db, 'therapists/' + therapist?.id), {
          profilePicture: { assetUrl: downloadURL, tpye: 'image', name: file.name },
          profilePictureUrl: downloadURL,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Bitte verwende nur Dateien vom Typ JPG oder PNG.');
    }
  };

  const handleChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  return {
    items,
    title,
    avatarUrl,
    handleChangeAvatar,
    inputRef,
    onButtonClick,
    uploading: loading,
  };
};
