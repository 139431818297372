import { getFunctions, httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { useMainStore } from '../../../store/mainStore';

export const useHandleChangePracticePin = (practiceId?: string) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [logoutTherapists, setLogoutTherapists] = useState(false);
  const [loading, setLoading] = useState(false);
  const changePin = async () => {
    if (!practiceId) return;
    try {
      setLoading(true);
      const functions = getFunctions();
      const generateNewPracticePin = httpsCallable(functions, 'generateNewPracticePin');
      const data = (await generateNewPracticePin({ practiceId, logoutTherapists })).data as {
        status: string;
        message: string;
      };
      if (data?.status !== 'success') {
        throw new Error(data?.message);
      }
      setEventIndicator('success', 'Praxis-Pin erfolgreich geändert');
    } catch (error) {
      console.error(error);
      setEventIndicator('error', 'Fehler beim Ändern des Praxis-Pins');
    } finally {
      setLoading(false);
    }
  };

  return {
    logoutTherapists,
    setLogoutTherapists,
    changePin,
    loading,
  };
};
