import Skeleton from 'react-loading-skeleton';
import QRCode from 'react-qr-code';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { PinDisplay } from '../../../core/components/pin/PinDisplay';
import { Divider } from '../../../core/components/SharedComponents';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { useIntercomOpenArticle } from '../../../core/utils/useIntercomOpenArticle';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { colors, getMediumRGBA } from '../../../styles/colors';
import { H3, Small } from '../../../styles/textStyles';
import { useHandlePracticeLinks } from '../utils/useHandlePracticeLinks';

type PracticeLinkContainerProps = {
  practiceId: string;
};

export const PracticeLink = ({ practiceId }: PracticeLinkContainerProps) => {
  const { loadingPractice, practiceLink, practiceName, copyLink, openLink } = useHandlePracticeLinks(practiceId);
  const { toggleIntercom } = useIntercomOpenArticle();

  const openInfo = () => {
    toggleIntercom(10546068);
  };

  return (
    <>
      {!practiceLink ? (
        <Skeleton width={'100%'} height={200} borderRadius={20} />
      ) : (
        <Container>
          {loadingPractice ? (
            <>
              <Skeleton width={120} height={120} />
              <div style={{ width: '100%' }}>
                <Skeleton height={20} count={2} />
              </div>
            </>
          ) : (
            <OuterContainer>
              <QRCodeContainer>
                <div>
                  <QRCode size={120} value={practiceLink} viewBox={`0 0 120 120`} />
                </div>
                <InnerContainer>
                  <LinkContainer>
                    <H3>{`Praxislink Standort ${practiceName}`}</H3>
                    <StyledSmall onClick={openLink}>{practiceLink}</StyledSmall>
                  </LinkContainer>
                  <LinkButtonsContainer>
                    <TextButton noPadding onClick={openLink} text="Öffnen" />
                    <TextButton noPadding onClick={copyLink} text="Link Kopieren" />
                  </LinkButtonsContainer>
                  <InfoButton iconName={'Info'} onClick={openInfo} stroke={colors.Medium} />
                </InnerContainer>
              </QRCodeContainer>

              <StyledDivider />
              <PinDisplay practiceId={practiceId} />
            </OuterContainer>
          )}
        </Container>
      )}
      <Outlet />
    </>
  );
};

const StyledDivider = styled(Divider)`
  background-color: ${hexWithOpacity(colors.Medium, 0.9)};
`;

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${getMediumRGBA(0.05)};
  padding: 20px;
  border-radius: 20px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
  > div:first-child {
    height: auto;
    margin: 0 auto;
    max-width: 120px;
    width: 100%;
    > :first-child {
      width: 100%;
      height: auto;
    }
  }
`;

const InfoButton = styled(IconButton)`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const InnerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  gap: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  text-decoration: underline;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 365px;
  cursor: pointer;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-self: center;
  }
`;
